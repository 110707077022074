"use client";
import { useState } from "react";
import axios from "axios";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { z } from "zod";
import dynamic from "next/dynamic";
const PhoneInput = dynamic(() => import("react-phone-input-2"), { ssr: false });
import { useRouter } from "next/navigation";

// Схема валідації за допомогою zod
const formSchema = z.object({
  name_modal: z
    .string()
    .trim()
    .min(1, "Ім'я є обов'язковим")
    .max(100, "Ім'я занадто довге"),
  countryCode: z.string().trim(),
  phone_modal: z
    .string()
    .min(1, "Телефон є обов'язковим")
    .max(15, "Телефон занадто довгий")
    .regex(/^\+?[0-9]{12,14}$/, "Невірний формат номера телефону"),
  date_modal: z.string().optional(),
  tag: z.string().optional(),
});

export interface ModalProps {
  btnMeta?: {
    tag: string;
  };
  color?: "green" | "blue" | "violet";
  closeForm: () => void;
}
type FormFields = {
  name_modal: string;
  phone_modal: string;
  date_modal?: string;
};

export default function ModalForm({
  btnMeta,
  closeForm,
  color = "green",
}: ModalProps) {
  const [formData, setFormData] = useState({
    name_modal: "",
    phone_modal: "",
    date_modal: "",
    countryCode: "UA",
    tag: btnMeta?.tag || "",
  });
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({
    name_modal: "",
    phone_modal: "",
    date_modal: "",
  });
  const [success, setSuccess] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setErrors({ ...errors, [e.target.name]: "" });
  };

  const handlePhoneChange = (
    value: string,
    country: { countryCode: string }
  ) => {
    setFormData({
      ...formData,
      phone_modal: value,
      countryCode: country.countryCode,
    });
    setErrors({ ...errors, phone_modal: "" });
  };

  const router = useRouter();
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setErrors({ name_modal: "", phone_modal: "", date_modal: "" });
    const validationResult = formSchema.safeParse(formData);
    if (!validationResult.success) {
      const newErrors: FormFields = {
        name_modal: "",
        phone_modal: "",
        date_modal: "",
      };

      validationResult.error.errors.forEach((curr) => {
        const fieldName = curr.path[0] as keyof FormFields; // Вказуємо тип
        newErrors[fieldName] = curr.message;
      });

      setErrors({ date_modal: "", ...newErrors });
      setLoading(false);
      return;
    }

    const utmTags = JSON.parse(localStorage.getItem("utmTags") || "{}");

    try {
      await axios.post("https://ap-server-8qi1.onrender.com/leads", {
        tag: validationResult.data.tag,
        name: validationResult.data.name_modal,
        phone: validationResult.data.phone_modal,
        time: validationResult.data.date_modal,
        ...utmTags,
      });
      setSuccess(true);
      router.push("/thankyou");
    } catch (error) {
      setErrors((prev) => ({
        ...prev,
        phone: "Форма не надіслана, спробуйте ще раз.",
      }));
    } finally {
      setLoading(false);
    }
  };
  const backgroundColorClass =
    {
      green: "custom-gradient-green-btn",
      blue: "custom-gradient-blue-btn",
      violet: "custom-gradient-violet-btn",
    }[color] || "custom-gradient-green-btn";
  const borderColorClass =
    {
      green: "border-green-500",
      blue: "border-blue-500",
      violet: "border-violet-500",
    }[color] || "border-green-500";
  const textColorClass =
    {
      green: "text-green-500",
      blue: "text-blue-500",
      violet: "text-violet-500",
    }[color] || "text-green-500";
  return (
    <div
      data-meta="ModalForm"
      className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75"
    >
      <div className="w-fit h-fit max-w-7xl flex justify-center">
        <div className="bg-white rounded-3xl p-4">
          <div className="flex justify-end">
            <button
              onClick={closeForm}
              className={`text-white border ${borderColorClass} rounded-full p-2`}
            >
              <XMarkIcon className={`h-4 w-4 ${textColorClass}`} />
            </button>
          </div>
          <div className="p-8 flex flex-col">
            <div className="text-center">
              <p className="text-2xl font-bold">
                Бажаєте отримати консультацію?
              </p>
              <p className="text-sm text-gray-500">
                Залиште заявку і наш менеджер вам зателефонує
              </p>
            </div>
            <form className="mt-4" onSubmit={handleSubmit}>
              <div className="grid grid-cols-1 gap-4">
                <div>
                  <label
                    htmlFor="name-modal"
                    className="sr-only block text-sm font-medium text-gray-700"
                  >
                    Ім'я
                  </label>
                  <input
                    type="text"
                    name="name_modal"
                    id="name-modal"
                    placeholder="Ім'я"
                    autoComplete="off"
                    className={`mt-1 block w-full px-3 py-2 border border-gray-300 rounded-full focus:shadow-md focus:border-gray-300 focus:ring-0  sm:text-sm`}
                    value={formData.name_modal}
                    onChange={handleChange}
                    required
                  />
                  {errors.name_modal && (
                    <p className="mt-1 text-red-500 text-sm">
                      {errors.name_modal}
                    </p>
                  )}
                </div>
                <div>
                  <label
                    htmlFor="phone-modal"
                    className="sr-only block text-sm font-medium text-gray-700"
                  >
                    Телефон
                  </label>
                  <PhoneInput
                    country={"ua"}
                    excludeCountries={["ru"]}
                    value={formData.phone_modal}
                    placeholder="Телефон"
                    onChange={handlePhoneChange}
                    containerClass={`mt-1 block w-full`}
                    inputClass={`w-full px-3 py-2 rounded-full   border ${
                      errors.phone_modal ? "border-red-500" : "border-gray-300"
                    }  sm:text-sm focus:shadow-md focus:border-gray-300 focus:ring-0`}
                    buttonClass="hover:bg-inherit focus:bg-inherit bg-inherit border-l-0 border-gray-300"
                    inputProps={{
                      name: "phone_modal",
                      id: "phone-modal",
                      required: true,
                    }}
                    containerStyle={{
                      borderColor: "#fff",
                    }}
                    inputStyle={{
                      width: "100%",
                      height: "2.25rem",
                      fontSize: "0.875rem",
                      backgroundColor: "#fff",
                      borderRadius: "2rem",
                    }}
                    buttonStyle={{
                      backgroundColor: "inherit",
                      borderRadius: "2rem 0 0 2rem",
                      borderWidth: "1px",
                      borderColor: "#e2e8f0",
                    }}
                  />
                  {errors.phone_modal && (
                    <p className="mt-1 text-red-500 text-sm">
                      {errors.phone_modal}
                    </p>
                  )}
                </div>
                <div>
                  <label
                    htmlFor="date"
                    className="sr-only block text-sm font-medium text-gray-700"
                  >
                    Коли вам зателефонувати?
                  </label>
                  <input
                    type="text"
                    name="date_modal"
                    id="date"
                    placeholder="Коли вам зателефонувати?"
                    autoComplete="off"
                    className={`mt-1 block w-full px-3 py-2 border border-gray-300 rounded-full focus:shadow-md focus:border-gray-300 focus:ring-0  sm:text-sm`}
                    value={formData.date_modal}
                    onChange={handleChange}
                  />
                  {errors.date_modal && (
                    <p className="mt-1 text-red-500 text-sm">
                      {errors.date_modal}
                    </p>
                  )}
                </div>
              </div>
              <button
                type="submit"
                disabled={loading}
                className={`${backgroundColorClass} uppercase mt-4 text-white rounded-full px-8 py-2.5 text-center text-sm font-fixel font-bold shadow-sm focus-visible:outline`}
              >
                {loading ? "Надсилання..." : "Надіслати"}
              </button>
            </form>
            {success && (
              <p className="mt-2 text-green-500 text-sm">
                Форма успішно надіслана!
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
