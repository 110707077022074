export interface BlockHeaderProps {
  title: string;
  subtitle?: string;
}

export default function BlockHeader({ title, subtitle }: BlockHeaderProps) {
  return (
    <div
      data-meta="BlockHeader"
      className="container max-w-7xl mx-auto py-6 sm:py-4"
    >
      <div className="mx-auto text-center">
        <h2 className="text-3xl font-bold tracking-tight text-white sm:text-6xl mb-4 sm:mb-8">
          {title}
        </h2>
        {subtitle && (
          <p className="mt-6 text-base lg:text-lg md:leading-6 lg:leading-8 text-white px-4">
            {" "}
            {subtitle}{" "}
          </p>
        )}
      </div>
    </div>
  );
}
