"use client";
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from "@headlessui/react";
import { PlusIcon, XMarkIcon } from "@heroicons/react/24/outline";

export interface QACustomProps {
  color?: "green" | "blue" | "violet";
  title: string;
  faqs?: {
    question: string;
    answer: string;
  }[];
}

export default function QACustom({
  color = "green",
  title,
  faqs,
}: QACustomProps) {
  const backgroundColorClass =
    {
      green: "custom-gradient-green-btn",
      blue: "custom-gradient-blue-btn",
      violet: "custom-gradient-violet-btn",
    }[color] || "custom-gradient-green-btn";

  return (
    <div data-meta="QACustom" className="mx-auto max-w-7xl">
      <div className="mx-auto py-6 sm:py-6 lg:py-12">
        <div className="mx-auto divide-y divide-gray-900/10">
          <div className="container max-w-7xl mx-auto py-6 sm:py-4">
            <div className="mx-auto text-center">
              <h2 className="text-3xl font-bold tracking-tight text-white sm:text-6xl mb-4 sm:mb-8">
                {title}
              </h2>
            </div>
          </div>
          <div className="flex flex-col bg-white rounded-3xl px-10 py-6 mt-10 space-y-2 divide-y divide-gray-900/10">
            {faqs &&
              faqs.map((faq, index) => (
                <Disclosure key={index} as="div" className="pt-6">
                  <div>
                    <DisclosureButton className="group flex w-full items-start justify-between text-left text-gray-900">
                      <span className="text-base font-semibold leading-7">
                        {faq.question}
                      </span>
                      <span
                        className={`ml-6 p-1 flex items-center rounded-full ${backgroundColorClass}`}
                      >
                        <PlusIcon
                          aria-hidden="true"
                          className="text-white h-8 w-8 group-data-[open]:hidden"
                        />
                        <XMarkIcon
                          aria-hidden="true"
                          className="text-white h-8 w-8 [.group:not([data-open])_&]:hidden"
                        />
                      </span>
                    </DisclosureButton>
                  </div>
                  <DisclosurePanel
                    as="div"
                    unmount={false}
                    className="mt-2 pr-12"
                  >
                    <p className="text-base leading-7 text-gray-600">
                      {faq.answer}
                    </p>
                  </DisclosurePanel>
                </Disclosure>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}
